<template>
  <div>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <el-form :inline="true" :model="formInline" class="demo-form-inline" ref="searchform">
            <el-form-item :label="$t('role.title')" prop="title">
              <el-input :placeholder="$t('role.title')" v-model="formInline.title"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="seachFun" type="primary">{{ $t('device.inquire') }}</el-button>
              <el-button @click="resetFun" type="primary">{{ $t('device.reset') }}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-button @click="addPower" type="primary" v-if="$store.state.pageAction.indexOf('device.add')> -1">
          {{ $t('device.add') }}
        </el-button>
      </template>
      <el-table
          :data="appData"
          :default-sort="{prop: 'date', order: 'descending'}"
          border
          stripe
          style="width: 100%"
      >
        <el-table-column :label="$t('role.title')" prop="title"></el-table-column>
        <!--        <el-table-column prop="mobile" label="归属客户"></el-table-column>-->
        <el-table-column :label="$t('order.status')" prop="status_title"></el-table-column>
        <el-table-column :label="$t('device.creation_time')" prop="create_at"></el-table-column>
        <el-table-column :label="$t('device.operating')" width="400">
          <template #default="scope">
            <el-button @click="handleEdit(scope.row)" size="mini"
                       v-if="$store.state.pageAction.indexOf('device.edit')> -1">{{ $t('device.edit') }}
            </el-button>
            <el-button @click="resetCode(scope.row)" size="mini"
                       v-if="$store.state.pageAction.indexOf('device.status')> -1">{{
                scope.row.status == 1 ? $t('role.disable') : $t('role.enable')
              }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog center :title="$t('role.add')" top="10vh" v-model="addPop" width="50%">
        <el-form :model="addForm">
          <el-form-item :label="$t('role.title')" label-width="120px" prop="title">
            <el-input v-model="addForm.title"></el-input>
          </el-form-item>
          <!--          <el-form-item label="归属客户" label-width="120px" prop="client">-->
          <!--            <el-input v-model="addForm.client"></el-input>-->
          <!--          </el-form-item>-->
          <el-form-item :label="$t('role.default')" label-width="120px" prop="status">
            <el-radio :label="1" v-model="addForm.is_client">{{ $t('role.yes') }}</el-radio>
            <el-radio :disabled="addForm.is_client==1" :label="2" v-model="addForm.is_client">{{
                $t('role.no')
              }}
            </el-radio>
          </el-form-item>
          <el-form-item :label="$t('order.status')" label-width="120px" prop="status">
            <el-radio :label="1" v-model="addForm.status">{{ $t('role.enable') }}</el-radio>
            <el-radio :label="2" v-model="addForm.status">{{ $t('role.disable') }}</el-radio>
          </el-form-item>
          <el-form-item :label="$t('role.select')" label-width="120px" prop="content">
            <el-tree
                :data="menus"
                :default-checked-keys="checkKeys"
                :default-expand-all="true"
                :props="defaultProps"
                @check-change="handleTree"
                node-key="path"
                ref="tree"
                show-checkbox
            >
            </el-tree>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="addPop = false">{{ $t('device.cancel') }}</el-button>
            <el-button @click="handleSubmit" type="primary">{{ $t('device.confirm') }}</el-button>
          </span>
        </template>
      </el-dialog>
      <el-pagination
          :page-size="formInline.size"
          :page-sizes="[10, 20,50]"
          :total="listCount"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          fixed="right"
          layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </el-card>
  </div>
</template>
<script>
import role from "../../model/role";

export default {
  name: "user",
  data() {
    return {
      dialogFormVisible: false,
      formInline: {
        title: "",
        page: 1,
        size: 10
      },
      resetform: {
        uid: "",
        name: "",
        password: ""
      },
      listCount: 0,
      appData: [],
      addPop: false,
      addForm: {
        id: "",
        title: "",
        status: "",
        content: "",
        is_client: 2
      },
      checkKeys: [],
      menus: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      }
    };
  },
  async mounted() {
    await this.loadData();
    let no = 1
    this.$router.options.routes.forEach(v => {
      if (!v.meta.hidden) {
        let data = {
          id: no,
          title: this.$t(v.meta.title),
          value: v.meta.title,
          path: v.path,
          icon: v.meta.icon,
          children: []
        }
        no++
        if (v.children) {
          v.children.forEach(i => {
            if (!i.meta.hidden) {
              let action = []
              if (i.meta.action) {
                i.meta.action.forEach(j => {
                  action.push({
                    id: no,
                    title: this.$t(j),
                    value: j,
                    path: i.path + '/' + j,
                    icon: '',
                    children: []
                  })
                  no++
                })
              }
              data.children.push({
                id: no,
                title: this.$t(i.meta.title),
                value: i.meta.title,
                path: i.path,
                icon: i.meta.icon,
                children: action
              })
              no++
            }
          })
        }
        this.menus.push(data)
      }
    })
  },
  methods: {
    async loadData() {
      const ret = await role.lists(this.formInline);
      if (ret.code === 200) {
        this.appData = ret.data.list;
        this.listCount = ret.data.count;
      }
    },
    async handleSubmit() {
      // 处理选中菜单
      const content = []
      this.menus.forEach(v => {
        let checked = false
        let data = {
          title: v.value,
          path: v.path,
          icon: v.icon,
          children: []
        }
        v.children.forEach(i => {
          let action = []
          let actionStatus = false
          if (i.children.length > 0) {
            i.children.forEach(j => {
              if (this.checkKeys.indexOf(j.path) > -1) {
                action.push({
                  title: j.value,
                  path: j.path,
                  value: j.value,
                  icon: j.icon,
                  children: []
                })
                checked = true
                actionStatus = true
              }
            })
          } else {
            if (this.checkKeys.indexOf(i.path) > -1) {
              checked = true
              actionStatus = true
            }
          }
          if (actionStatus) {
            data.children.push({
              title: i.value,
              path: i.path,
              icon: i.icon,
              children: action
            })
          }
        })
        if (checked) {
          content.push(data)
        }
      })
      this.addForm.content = JSON.stringify(content)
      const ret = await role.operate(this.addForm)
      if (ret.code === 200) {
        this.addPop = false
        this.$message.success(ret.message)
        this.loadData()
      } else {
        this.$message.warning(ret.message)
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleTree(node, nodes) {
      this.checkKeys = this.$refs.tree.getCheckedKeys()
    },
    async resetCode(row) {
      const ret = await role.status({
        id: row.id,
        status: row.status == 1 ? 2 : 1
      })
      if (ret.code === 200) {
        this.$message.success(ret.message)
        this.loadData()
      } else {
        this.$message.warning(ret.message)
      }
    },
    addPower() {
      this.addForm = {
        id: "",
        title: "",
        status: 1,
        content: "",
        is_client: 2
      }
      this.addPop = true;
      this.checkKeys = []
      setTimeout(() => {
        this.$refs.tree.setCheckedKeys([])
      })
    },
    handleEdit(row) {
      this.addPop = true;
      this.checkKeys = []
      this.addForm = JSON.parse(JSON.stringify(row))
      row.content.forEach(v => {
        if (v.children) {
          v.children.forEach(i => {
            if (i.children.length > 0) {
              i.children.forEach(j => {
                this.checkKeys.push(j.path)
              })
            } else {
              this.checkKeys.push(i.path)
            }
          })
        }
      })
    },
    seachFun() {
      this.loadData();
    },
    resetFun() {
      this.$refs.searchform.resetFields();
      this.loadData();
    },
    handleSizeChange(val) {
      this.formInline.size = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.formInline.page = val;
      this.loadData();
    }
  }
};
</script>
<style scoped>
.demo-form-inline {
  margin-top: 20px;
}

.card-header {
  display: flex;
  align-items: center;
}

.el-pagination {
  margin: 30px 0px;
  float: right;
}
</style>
<!--<style>-->
<!--.el-tree {-->
<!--  height: 100%;-->
<!--  width: 100%;-->
<!--  /*word-break: break-all;*/-->
<!--}-->
<!--.el-tree /deep/ .el-tree-node {-->
<!--  white-space: normal;-->
<!--}-->
<!--.el-tree .el-tree-node .el-tree-node__content {-->
<!--  height: 100%;-->
<!--  /*line-height: 22px;*/-->
<!--  align-items: start;-->
<!--}-->
<!--</style>-->
<style scoped lang="scss">
// 如果使用了sass时，也可以这样写，
// 使用了sass也可以像上面一样写，就写两个深度选择器
// 这个我也是F12，然后看到它们对应的关系的
//.el-tree {
//  width: 100%;
//  height: 100%;
//
//  :deep(.el-tree-node) {
//    white-space: normal;
//    outline: 0;
//
//    .el-tree-node__content {
//      text-align: left;
//      align-items: start;
//    }
//  }
//}
</style>
