import request from '../utils/request'

export default {
  async lists (data) {   // 获取角色列表
    const ret = await request('/v1/role/lists', data, 'POST')
    return ret
  },
  async operate (data) {    // 新增/修改用户信息
    const ret = await request('/v1/role/operate', data, 'POST')
    return ret
  },
  async map (data) {  // 获取全部权限列表
    const ret = await request('/v1/role/map', data, 'POST')
    return ret
  },
  async status (data) {  // 修改权限状态
    const ret = await request('/v1/role/status', data, 'POST')
    return ret
  }
}
